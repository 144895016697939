<template>
	<div class="page-403 d-flex align-items-center justify-content-center">
		<div>
			<div class="icon mb-3">
				<CIcon
					name="cil-lock-locked"
					size="custom"
					custom-classes="c-icon c-icon-custom-size lock-icon"
				/>
			</div>
			<h1 class="title mb-1">
				You don’t have permission to view or manage this page.
			</h1>
			<p class="typo-body-2 color-black-45 mb-0">
				Please contact your administrator to obtain permission.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageForbidden',
};
</script>

<style lang="scss" scoped>
	.page-403 {
		position: absolute; // relative with .c-body
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		align-items: center;
		text-align: center;
	}

	.icon {
		display: inline-block;
		width: rem(80);
		height: rem(80);
		line-height: rem(80);
		background-color: $color-gray-100;
		text-align: center;
		font-size: rem(28);
		color: $color-gray-500;
		font-weight: $font-weight-bold;
		border-radius: 50%;
	}

	.title {
		font-size: rem(18);
		font-weight: $font-weight-bold;
		color: $color-gray-500;
	}

	.lock-icon {
		width: rem(40);
		height: rem(40);
	}
</style>
